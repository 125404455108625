import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowInfoModal } from 'store/reducers/User/UserSlice';
import InfoButton from '../Elemente/InfoButton';
import Button from '../../../components/ui/Button';
import infoIcon from '../../../assets/info.svg';

import { ReactComponent as Arrow } from '../../../assets/arrow-top.svg';

const allMapSglArr = [
  'models/mapSglAll/DB_703_feinstruktur.jpg',
  'models/mapSglAll/DB_703_seidenglanz.jpg',
  'models/mapSglAll/RAL_7016_feinstruktur.jpg',
  'models/mapSglAll/RAL_7016_seidenglanz.jpg',
  'models/mapSglAll/RAL_9006_feinstruktur.jpg',
  'models/mapSglAll/RAL_9006_seidenglanz.jpg',
  'models/mapSglAll/RAL_9007_feinstruktur.jpg',
  'models/mapSglAll/RAL_9007_seidenglanz.jpg',
  'models/mapSglAll/RAL_9016_feinstruktur.jpg',
  'models/mapSglAll/RAL_9016_seidenglanz.jpg',
];
const zipMapSklSideArr = [
  'models/zipMapSklSide/001001_grey_grey.jpg',
  'models/zipMapSklSide/001002_grey_white.jpg',
  'models/zipMapSklSide/001010_grey_charcoal.jpg',
  'models/zipMapSklSide/002002_white_white.jpg',
  'models/zipMapSklSide/002007_white_pearl_grey.jpg',
  'models/zipMapSklSide/003002_sand_white.jpg',
  'models/zipMapSklSide/003003_sand_sand.jpg',
  'models/zipMapSklSide/007007_pearl_grey_pearl_grey.jpg',
  'models/zipMapSklSide/008002_linen_white.jpg',
  'models/zipMapSklSide/008008_linen_linen.jpg',
  'models/zipMapSklSide/010010_charcoal_charcoal.jpg',
];
const zipMapSklSideSolarArr = [
  'models/zipMapSklSolarSide/Weiss.jpg',
  'models/zipMapSklSolarSide/Champagnen.jpg',
  'models/zipMapSklSolarSide/Grau.jpg',
  'models/zipMapSklSolarSide/Taubengrau.jpg',
  'models/zipMapSklSolarSide/Dunkelbraun.jpg',
];
const allMapSklArr = [
  'models/mapSklAll/CORTEN_feinstruktur.jpg',
  'models/mapSklAll/OLD_BLACK_feinstruktur.jpg',
  'models/mapSklAll/OLD_GREEN_feinstruktur.jpg',
  'models/mapSklAll/OLD_GREY_feinstruktur.jpg',
  'models/mapSklAll/RAL_1001_Beige_feinstruktur.jpg',
  'models/mapSklAll/RAL_1013_Perlweiss_feinstruktur.jpg',
  'models/mapSklAll/RAL_1015_Hellelfenbein_feinstruktur.jpg',
  'models/mapSklAll/RAL_1019_Graubeige_feinstruktur.jpg',
  'models/mapSklAll/RAL_6005_Moosgruen_feinstruktur.jpg',
  'models/mapSklAll/RAL_7016_Antrazithgrau_feinstruktur.jpg',
  'models/mapSklAll/RAL_7030_Steingrau_feinstruktur.jpg',
  'models/mapSklAll/RAL_7035_Lichtgrau_feinstruktur.jpg',
  'models/mapSklAll/RAL_7039_Quarzgrau_feinstruktur.jpg',
  'models/mapSklAll/RAL_8017_Schokoladenbraun_feinstruktur.jpg',
  'models/mapSklAll/RAL_8019_Graubraun_feinstruktur.jpg',
  'models/mapSklAll/RAL_9001_Cremeweiss_feinstruktur.jpg',
  'models/mapSklAll/RAL_9006_Weissaluminium_seidenmatt.jpg',
  'models/mapSklAll/RAL_9006_Weissaluminium_feinstruktur.jpg',
  'models/mapSklAll/RAL_9007_Graualuminium_seidenmatt.jpg',
  'models/mapSklAll/RAL_9007_Graualuminium_feinstruktur.jpg',
  'models/mapSklAll/RAL_9016_Verkehrsweiss_feinstruktur.jpg',
  'models/mapSklAll/Sage_G.jpg',
  'models/mapSklAll/Starlight_G.jpg',
  'models/mapSklAll/Tujka_feinstruktur.jpg',
  'models/mapSklAll/White_646_S_seidenglanz.jpg',
];
const allMapSklSolarArr = [
  'models/mapSklSolarAll/Grau.jpg',
  'models/mapSklSolarAll/Weiss.jpg',
  'models/mapSklSolarAll/Taubengrau.jpg',
  'models/mapSklSolarAll/Dunkelbraun.jpg',
];
const zipMapSglSideArr = [
  'models/zipMapSglSide/0102.jpg',
  'models/zipMapSglSide/0103.jpg',
  'models/zipMapSglSide/0105.jpg',
  'models/zipMapSglSide/0108.jpg',
  'models/zipMapSglSide/0109.jpg',
  'models/zipMapSglSide/0130.jpg',
  'models/zipMapSglSide/0140.jpg',
  'models/zipMapSglSide/0150.jpg',
  'models/zipMapSglSide/0202.jpg',
  'models/zipMapSglSide/0205.jpg',
  'models/zipMapSglSide/0207.jpg',
  'models/zipMapSglSide/0210.jpg',
  'models/zipMapSglSide/0505.jpg',
  'models/zipMapSglSide/0606.jpg',
  'models/zipMapSglSide/0701.jpg',
  'models/zipMapSglSide/0702.jpg',
  'models/zipMapSglSide/0703.jpg',
  'models/zipMapSglSide/0705.jpg',
  'models/zipMapSglSide/0707.jpg',
  'models/zipMapSglSide/0740.jpg',
  'models/zipMapSglSide/0750.jpg',
  'models/zipMapSglSide/0909.jpg',
  'models/zipMapSglSide/1111_Дhnlich_RAL_7048.jpg',
  'models/zipMapSglSide/2001.jpg',
  'models/zipMapSglSide/2002.jpg',
  'models/zipMapSglSide/2020.jpg',
  'models/zipMapSglSide/3030.jpg',
  'models/zipMapSglSide/4040.jpg',
  'models/zipMapSglSide/4949_Дhnlich_RAL_9006.jpg',
  'models/zipMapSglSide/5959_Дhnlich_RAL_7016.jpg',
  'models/zipMapSglSide/6060_Дhnlich_RAL_9005.jpg',
  'models/zipMapSglSide/M01.jpg',
  'models/zipMapSglSide/M02.jpg',
  'models/zipMapSglSide/M31.jpg',
  'models/zipMapSglSide/M36.jpg',
  'models/zipMapSglSide/M45.jpg',
  'models/zipMapSglSide/M64.jpg',
  'models/zipMapSglSide/M65.jpg',
];
const zipMapSglTopArr = [
  'models/zipMapSglTop/314_001_China_Red.jpg',
  'models/zipMapSglTop/314_002_Orange.jpg',
  'models/zipMapSglTop/314_003_Dandelion.jpg',
  'models/zipMapSglTop/314_004_Jungle.jpg',
  'models/zipMapSglTop/314_005_Tangerine.jpg',
  'models/zipMapSglTop/314_006_Classic_Royal_Blue.jpg',
  'models/zipMapSglTop/314_007_Lilly.jpg',
  'models/zipMapSglTop/314_010_Cloud.jpg',
  'models/zipMapSglTop/314_011_Nautical.jpg',
  'models/zipMapSglTop/314_013_Nut.jpg',
  'models/zipMapSglTop/314_014_Mandarin.jpg',
  'models/zipMapSglTop/314_016_Mahogany.jpg',
  'models/zipMapSglTop/314_018_Whisper.jpg',
  'models/zipMapSglTop/314_020_Shell.jpg',
  'models/zipMapSglTop/314_022_Copper.jpg',
  'models/zipMapSglTop/314_028_Graphite.jpg',
  'models/zipMapSglTop/314_030_Fog.jpg',
  'models/zipMapSglTop/314_033_Cream.jpg',
  'models/zipMapSglTop/314_035_Vanilla.jpg',
  'models/zipMapSglTop/314_040_Camel.jpg',
  'models/zipMapSglTop/314_045_Daffodil.jpg',
  'models/zipMapSglTop/314_050_Nile.jpg',
  'models/zipMapSglTop/314_051_Stellar.jpg',
  'models/zipMapSglTop/314_062_Spring.jpg',
  'models/zipMapSglTop/314_070_Oyster.jpg',
  'models/zipMapSglTop/314_071_Dune.jpg',
  'models/zipMapSglTop/314_072_Bark.jpg',
  'models/zipMapSglTop/314_080_Metal.jpg',
  'models/zipMapSglTop/314_081_Greige.jpg',
  'models/zipMapSglTop/314_082_Pigeon.jpg',
  'models/zipMapSglTop/314_083_Ash.jpg',
  'models/zipMapSglTop/314_083_Forge.jpg',
  'models/zipMapSglTop/314_084_Shale.jpg',
  'models/zipMapSglTop/314_085_Rocks.jpg',
  'models/zipMapSglTop/314_143_Plum.jpg',
  'models/zipMapSglTop/314_153_Sky.jpg',
  'models/zipMapSglTop/314_154_Black.jpg',
  'models/zipMapSglTop/314_171_Bone.jpg',
  'models/zipMapSglTop/314_182_Pearl.jpg',
  'models/zipMapSglTop/314_182_Pebble.jpg',
  'models/zipMapSglTop/314_184_Smoke.jpg',
  'models/zipMapSglTop/314_223_Apricot.jpg',
  'models/zipMapSglTop/314_325_Almond.jpg',
  'models/zipMapSglTop/314_328_Spice.jpg',
  'models/zipMapSglTop/314_344_Sprinkles.jpg',
  'models/zipMapSglTop/314_347_Cherry.jpg',
  'models/zipMapSglTop/314_362_Forest_Green.jpg',
  'models/zipMapSglTop/314_364_Quarryjpg.jpg',
  'models/zipMapSglTop/314_396_Leaf.jpg',
  'models/zipMapSglTop/314_397_Magenta.jpg',
  'models/zipMapSglTop/314_398_Storm_Grey.jpg',
  'models/zipMapSglTop/314_402_Night_Sky.jpg',
  'models/zipMapSglTop/314_403_Oak.jpg',
  'models/zipMapSglTop/314_404_Scarlet.jpg',
  'models/zipMapSglTop/314_414_Marine.jpg',
  'models/zipMapSglTop/314_471_Birch.jpg',
  'models/zipMapSglTop/314_488_Merlot.jpg',
  'models/zipMapSglTop/314_525_Space.jpg',
  'models/zipMapSglTop/314_546_Maya_Blue.jpg',
  'models/zipMapSglTop/314_550_Midnigth.jpg',
  'models/zipMapSglTop/314_580_Jasmine.jpg',
  'models/zipMapSglTop/314_583_Straw.jpg',
  'models/zipMapSglTop/314_610_Saffron.jpg',
  'models/zipMapSglTop/314_621_Seagrass.jpg',
  'models/zipMapSglTop/314_624_Avocado.jpg',
  'models/zipMapSglTop/314_660_Sage.jpg',
  'models/zipMapSglTop/314_680_Pewter.jpg',
  'models/zipMapSglTop/314_703_Fawn.jpg',
  'models/zipMapSglTop/314_718_Ecru.jpg',
  'models/zipMapSglTop/314_721_Linen.jpg',
  'models/zipMapSglTop/314_723_Sea_Salt.jpg',
  'models/zipMapSglTop/314_724_Mimosa.jpg',
  'models/zipMapSglTop/314_727_Dijon.jpg',
  'models/zipMapSglTop/314_729_Nutmeg.jpg',
  'models/zipMapSglTop/314_763_Burgundy.jpg',
  'models/zipMapSglTop/314_780_Dove.jpg',
  'models/zipMapSglTop/314_803_Satellite.jpg',
  'models/zipMapSglTop/314_814_Cashew.jpg',
  'models/zipMapSglTop/314_818_Nimbus.jpg',
  'models/zipMapSglTop/314_819_Cave.jpg',
  'models/zipMapSglTop/314_828_Biscotti.jpg',
  'models/zipMapSglTop/314_838_Blossom.jpg',
  'models/zipMapSglTop/314_840_Asphalt.jpg',
  'models/zipMapSglTop/314_851_Milky.jpg',
  'models/zipMapSglTop/314_858_Denim.jpg',
  'models/zipMapSglTop/314_868_Laurel.jpg',
  'models/zipMapSglTop/314_880_Gunmetal.jpg',
  'models/zipMapSglTop/314_881_Ivy.jpg',
  'models/zipMapSglTop/314_888_Shadow.jpg',
  'models/zipMapSglTop/314_910_Snow.jpg',
  'models/zipMapSglTop/314_917_Earth.jpg',
  'models/zipMapSglTop/314_919_Desert.jpg',
  'models/zipMapSglTop/314_921_Hazel.jpg',
  'models/zipMapSglTop/314_941_Scree.jpg',
  'models/zipMapSglTop/314_947_Mellow.jpg',
  'models/zipMapSglTop/314_E67_Ivory.jpg',
];

const ItemImgMap = ({ color, source, type }) => {
  const ref = useRef();

  const [position, setPosition] = useState(null);

  const hover = () => {
    const { x, y } = ref.current.getBoundingClientRect();
    setPosition({ x, y });
  };

  return (
    <div
      className="accordion-option-color"
      onMouseEnter={hover}
      ref={ref}
      style={{ backgroundImage: `url(${color})`, backgroundSize: 'contain' }}
      onClick={() => {
        if (
          type === 'allMapSkl' ||
          type === 'allMapSklSolar' ||
          type === 'lammelenMapSkl'
        ) {
          source.funcChangeAllMapSkl(color, type);
        }
        if (type === 'allMapSgl') {
          source.funcChangeAllMapSgl(color);
        }
        if (type === 'zipMapSklSide' || type === 'zipMapSklSolarSide') {
          source.funcChangeZipMapSklSide(color);
        }
        if (type === 'zipMapSglSide') {
          source.funcChangeZipMapSglSide(color);
        }
        if (type === 'zipMapSglTop') {
          source.funcChangeZipMapSglTop(color);
        }
      }}
      role="presentation"
    >
      {type === 'allMapSkl' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/mapSklAll/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'allMapSklSolar' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/mapSklSolarAll/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'lammelenMapSkl' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/mapSklAll/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'allMapSgl' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/mapSglAll/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'zipMapSklSide' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/zipMapSklSide/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'zipMapSklSolarSide' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/zipMapSklSolarSide/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'zipMapSglSide' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/zipMapSglSide/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      {type === 'zipMapSglTop' ? (
        <div
          className="title-name-color"
          style={{
            left: position?.x,
            top: position?.y,
          }}
        >
          {color
            .replace('models/zipMapSglTop/', '')
            .replace('.jpg', '')
            .replace(/_/g, ' ')}
        </div>
      ) : null}

      <div className="div-hover"> </div>
    </div>
  );
};

const ColorSection = ({
  dispatch,
  title,
  array,
  isOpen,
  toggleOpen,
  type,
  source,
  expertMode,
}) => (
  <div className="accordion-item" style={expertMode && { marginTop: '0' }}>
    <div className="accordion-item-title">
      {title}{' '}
      <InfoButton
        title={title}
        text={`Wählen Sie zwischen ${array.length} verschiedenen Farben aus.`}
      />
      <Button
        className="info-icon-btn"
        onClick={() => {
          dispatch(
            setShowInfoModal({
              title,
              text: `Wählen Sie zwischen ${array.length} verschiedenen Farben aus.`,
              link: '',
            })
          );
        }}
      >
        <img src={infoIcon} alt="infoIcon" />
      </Button>
    </div>
    <div className="accordion-item-content color-option">
      <div
        className="color-option-color-wrap"
        style={{ display: 'flex', flexWrap: 'wrap', width: '336px' }}
      >
        {array
          .slice(0, expertMode ? array.length : isOpen ? array.length : 4)
          .map((color) => (
            <ItemImgMap key={color} color={color} source={source} type={type} />
          ))}
        {!expertMode && array.length > 4 && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div className="accordion-btn-allOption" onClick={toggleOpen}>
            <span>{isOpen ? 'Weniger anzeigen' : 'Mehr anzeigen'}</span>
            <Arrow className={isOpen ? 'arrow-svg rotate' : 'arrow-svg'} />
          </div>
        )}
      </div>
    </div>
    {!expertMode && <div className="line-or-accordion" />}
  </div>
);

const Farba = ({ expertMode = false }) => {
  const { source, typeModel } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const [openSections, setOpenSections] = useState({
    profilColor: false,
    lamellenColor: false,
    markiseColor: false,
  });

  const handleToggle = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const isArrZipEmpty =
    source.currentModel &&
    Array.isArray(source.currentModel.arrZip) &&
    source.currentModel.arrZip.length === 0;

  const isArrLamellenEmpty =
    source.currentModel &&
    Array.isArray(source.currentModel.arrLamellen) &&
    source.currentModel.arrLamellen.length === 0;

  const sections = {
    louver: [
      { title: 'Profilfarbe', array: allMapSklArr, type: 'allMapSkl' },
      { title: 'Lamellenfarbe', array: allMapSklArr, type: 'lammelenMapSkl' },
      ...(!isArrZipEmpty
        ? [{ title: 'Markise', array: zipMapSklSideArr, type: 'zipMapSklSide' }]
        : []),
    ],
    louver2: [
      { title: 'Profilfarbe', array: allMapSklArr, type: 'allMapSkl' },
      { title: 'Lamellenfarbe', array: allMapSklArr, type: 'lammelenMapSkl' },
      ...(!isArrZipEmpty
        ? [{ title: 'Markise', array: zipMapSklSideArr, type: 'zipMapSklSide' }]
        : []),
    ],

    louver3: [
      {
        title: 'Profilfarbe',
        array: allMapSklSolarArr,
        type: 'allMapSklSolar',
      },

      ...(!isArrLamellenEmpty
        ? [
            {
              title: 'Lamellenfarbe',
              array: allMapSklArr,
              type: 'lammelenMapSkl',
            },
          ]
        : []),

      ...(!isArrZipEmpty
        ? [
            {
              title: 'Markise',
              array: zipMapSklSideSolarArr,
              type: 'zipMapSklSolarSide',
            },
          ]
        : []),
    ],
    glass: [
      { title: 'Profilfarbe', array: allMapSglArr, type: 'allMapSgl' },
      { title: 'Dachmarkise', array: zipMapSglTopArr, type: 'zipMapSglTop' },
      ...(!isArrZipEmpty
        ? [{ title: 'Markise', array: zipMapSglSideArr, type: 'zipMapSglSide' }]
        : []),
    ],
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {sections[typeModel]?.map((section) => (
        <ColorSection
          dispatch={dispatch}
          key={section.title}
          title={section.title}
          array={section.array}
          isOpen={openSections[section.type]}
          toggleOpen={() => handleToggle(section.type)}
          type={section.type}
          source={source}
          expertMode={expertMode}
        />
      ))}
    </>
  );
};

export default Farba;
