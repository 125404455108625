import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSimpleBar from 'components/CustomSimpleBar';

import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import { ReactComponent as Info } from '../../../assets/info.svg';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowTypeZipModal } from '../../../store/reducers/User/UserSlice';

import zip1 from '../../../assets/options/zip1.jpg';
import zip2 from '../../../assets/options/zip2.jpg';

import zip3 from '../../../assets/options/zip3.jpg';
import zip4 from '../../../assets/options/zip4.jpg';

import './style.scss';
import { updateSideFilled } from '../../../store/reducers/Project/ProjectSlice';

const TypeZipModal = () => {
  const dispatch = useDispatch();

  const { showTypeZipModal, expertMode } = useSelector((state) => state.user);
  const { source, typeModel, selectedSideModel } = useSelector(
    (state) => state.project
  );
  const [typeZip, setTypeZip] = useState(null);

  const [toggleTypeZip, setToggleTypeZip] = useState(false);
  const [globalWidth, setGlobalWidth] = useState(0);

  const handleClose = () => {
    dispatch(setShowTypeZipModal(false));
    setTypeZip(null);
  };

  useEffect(() => {
    if (!showTypeZipModal || !source) return;
    setGlobalWidth(+source.globalWidth);
  }, [showTypeZipModal]);

  const select = () => {
    if (!typeZip) {
      toast.warn('Produkt auswählen');
      return;
    }
    const data = source.funcToggleZip(undefined, undefined, typeZip);
    if (data === undefined) {
      toast.warn('es ist verboten');
      return;
    }

    if (expertMode) {
      dispatch(updateSideFilled(selectedSideModel, true));
    } else {
      dispatch(updateSideFilled(source.current.name, true));
    }

    handleClose();
  };

  return (
    <ModalWrapper
      show={showTypeZipModal}
      close={handleClose}
      customClass="wrapper-type-zip-modal"
    >
      <ModalHeader title="ZIP Screen Einstellungen" close={handleClose} />

      <div className="container-type-zip-modal">
        <div className="section-type-zip-modal">
          <div className="title-section-type-zip-modal">
            Montage Position <Info />
          </div>

          <CustomSimpleBar style={{ maxHeight: '50dvh', width: '100%' }}>
            <div className="content-section-type-zip-modal">
              {typeModel === 'louver' ? (
                <>
                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType2'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType2');
                      }}
                    >
                      <img src={zip1} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Unten aufgesetzt
                    </div>
                    <div className="type-zip-modal-options-title">
                      keine Verglasung möglich
                    </div>
                  </div>

                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType1'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType1');
                      }}
                    >
                      <img src={zip2} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Front aufgesetzt
                    </div>
                    <div className="type-zip-modal-options-title">
                      Verglasung möglich
                    </div>
                  </div>

                  <div className="type-zip-modal-options-img disable">
                    <Button
                      className={
                        typeZip === '??????'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {}}
                    >
                      <img src={zip1} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Im Träger integriert
                    </div>
                    <div className="type-zip-modal-options-title">
                      auf Anfrage
                    </div>
                  </div>
                </>
              ) : null}

              {typeModel === 'louver3' ? (
                <>
                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType2'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType2');
                      }}
                    >
                      <img src={zip1} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Unten aufgesetzt
                    </div>
                    <div className="type-zip-modal-options-title">
                      keine Verglasung möglich
                    </div>
                  </div>

                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType1'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType1');
                      }}
                    >
                      <img src={zip2} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Front aufgesetzt
                    </div>
                    <div className="type-zip-modal-options-title">
                      Verglasung möglich
                    </div>
                  </div>

                  <div className="type-zip-modal-options-img disable">
                    <Button
                      className={
                        typeZip === '??????'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {}}
                    >
                      <img src={zip1} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title toptitle">
                      Im Träger integriert
                    </div>
                    <div className="type-zip-modal-options-title">
                      auf Anfrage
                    </div>
                  </div>
                </>
              ) : null}

              {typeModel === 'louver2' ? (
                <div className="type-zip-modal-options-img">
                  <Button
                    className={
                      typeZip === 'zipType1'
                        ? 'type-zip-modal-options-content active'
                        : 'type-zip-modal-options-content'
                    }
                    onClick={() => {
                      setTypeZip('zipType1');
                    }}
                  >
                    <img src={zip2} alt="" />
                  </Button>
                  <div className="type-zip-modal-options-title toptitle">
                    Front aufgesetzt
                  </div>
                  <div className="type-zip-modal-options-title">
                    Verglasung möglich
                  </div>
                </div>
              ) : null}

              {typeModel === 'glass' &&
              ['sideA', 'sideB', 'sideC', 'sideD'].includes(
                showTypeZipModal.variable
              ) ? (
                <div style={{ display: 'flex' }}>
                  {!toggleTypeZip ? (
                    <>
                      <div className="type-zip-modal-options-img">
                        <Button
                          className={
                            typeZip === 'zipType2'
                              ? 'type-zip-modal-options-content active'
                              : 'type-zip-modal-options-content'
                          }
                          onClick={() => {
                            setTypeZip('zipType2');
                          }}
                        >
                          <img src={zip3} alt="" />
                        </Button>
                        <div className="type-zip-modal-options-title toptitle">
                          Rückseite aufgesetzt
                        </div>
                        <div className="type-zip-modal-options-title">
                          Verglasung möglich
                        </div>
                      </div>
                      <div
                        className={
                          ['sideA', 'sideC'].includes(
                            showTypeZipModal.variable
                          ) ||
                          (['sideB', 'sideD'].includes(
                            showTypeZipModal.variable
                          ) &&
                            globalWidth > 5500)
                            ? 'type-zip-modal-options-img disable'
                            : 'type-zip-modal-options-img'
                        }
                      >
                        <Button
                          className={
                            typeZip === 'zipType1'
                              ? 'type-zip-modal-options-content active'
                              : 'type-zip-modal-options-content'
                          }
                          onClick={() => {
                            setTypeZip('zipType1');
                          }}
                        >
                          <img src={zip4} alt="" />
                        </Button>
                        <div className="type-zip-modal-options-title toptitle">
                          Vorne aufgesetzt
                        </div>
                        <div className="type-zip-modal-options-title">
                          Verglasung möglich
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="type-zip-modal-options-img">
                        <Button
                          className={
                            typeZip === 'zipType4'
                              ? 'type-zip-modal-options-content active'
                              : 'type-zip-modal-options-content'
                          }
                          onClick={() => {
                            setTypeZip('zipType4');
                          }}
                        >
                          <img src={zip3} alt="" />
                        </Button>
                        <div className="type-zip-modal-options-title toptitle">
                          Rückseite aufgesetzt
                        </div>
                        <div className="type-zip-modal-options-title">
                          Verglasung möglich
                        </div>
                      </div>
                      <div
                        className={
                          ['sideA', 'sideC'].includes(
                            showTypeZipModal.variable
                          ) ||
                          (['sideB', 'sideD'].includes(
                            showTypeZipModal.variable
                          ) &&
                            globalWidth > 5500)
                            ? 'type-zip-modal-options-img disable'
                            : 'type-zip-modal-options-img'
                        }
                      >
                        <Button
                          className={
                            typeZip === 'zipType3'
                              ? 'type-zip-modal-options-content active'
                              : 'type-zip-modal-options-content'
                          }
                          onClick={() => {
                            setTypeZip('zipType3');
                          }}
                        >
                          <img src={zip4} alt="" />
                        </Button>
                        <div className="type-zip-modal-options-title toptitle">
                          Vorne aufgesetzt
                        </div>
                        <div className="type-zip-modal-options-title">
                          Verglasung möglich
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </CustomSimpleBar>

          {typeModel === 'glass' &&
          ['sideA', 'sideB', 'sideC', 'sideD'].includes(
            showTypeZipModal.variable
          ) ? (
            <>
              <div className="title-section-type-zip-modal-tgl-btn">
                Runder Kasten
              </div>

              <div className="wrapper-toggle-btn-zip">
                <input
                  type="checkbox"
                  id="toggle-btn-zip"
                  className="toggle-btn-zip"
                  checked={toggleTypeZip}
                  onChange={(e) => {
                    setToggleTypeZip(e.target.checked);
                  }}
                />
                <label htmlFor="toggle-btn-zip"> </label>
              </div>
            </>
          ) : null}
        </div>
        <div className="container-btnStart-type-zip-modal">
          <Button className="btn-type-zip-modal" onClick={select}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TypeZipModal;
